<template>
  <div class="component" @click="goToMatch(match.match_id)">
    <div class="mb-2 d-flex justify-content-around w-100">
      <div class="d-flex flex-column">
        <img
          :src="match.home_team_logo"
          alt="Home Team Logo"
          height="30"
          width="30"
        />
        <strong
          ><span>{{ match.home_team_code }} </span></strong
        >
      </div>
      <strong><span>-</span></strong>
      <div class="d-flex flex-column">
        <img
          :src="match.away_team_logo"
          alt="Away Team Logo"
          height="30"
          width="30"
        />
        <strong
          ><span>{{ match.away_team_code }}</span></strong
        >
      </div>
    </div>
    <div class="text-light">
      <span>{{ formatDate(match.date_time) }}</span>
      <button></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    match: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goToMatch(id) {
      window.location.href = `matches_new/${id}`;
    },
    formatDate(date) {
      return new Date(date).toLocaleString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
};
</script>
<style scoped>
.component {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
</style>
